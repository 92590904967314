<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            ref="previewEl"
            size="80"
            rounded
            :src="profileData.avatar && `${server}${profileData.avatar}`"
            :text="avatarText(profileData.fio.replace(/[0-9]/g, ''))"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="refInputEl.click()"
        >
          <span v-if="profileData.avatar">Обновить</span>
          <span v-else>Загрузить</span>
        </b-button>
        <input
          ref="refInputEl"
          type="file"
          class="d-none"
          @input="inputImageRenderer"
        >
        <!--/ upload button -->
        <b-card-text>Допускаются JPG, GIF или PNG. Максимальный размер 800 КБ</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Фамилия"
              label-for="last_name"
            >
              <b-form-input
                v-model="profileData.last_name"
                name="last_name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Имя"
              label-for="first_name"
            >
              <b-form-input
                v-model="profileData.first_name"
                name="first_name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Отчество"
              label-for="middle_name"
            >
              <b-form-input
                v-model="profileData.middle_name"
                name="middle_name"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Город"
              label-for="city"
            >
              <b-form-input
                v-model="profileData.city"
                name="city"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Телефон"
              label-for="phone"
            >
              <b-form-input
                v-model="profileData.phone"
                name="phone"
                v-mask="'+7(###) ###-####'"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                v-model="profileData.email"
                name="email"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              <span>{{ $t('Save Changes') }}</span>
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t('Reset') }}
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
  BRow, BCol, BCard, BCardText, BMedia,
  BMediaAside, BMediaBody, BLink, BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const server = process.env.VUE_APP_SERVER

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, () => {
      store.dispatch('app/uploadImage', {
        image: refInputEl.value.files[0],
      }).then(res => {
        if (res.image) {
          // eslint-disable-next-line no-param-reassign
          props.profileData.avatar = `storage/${res.image}`

          store.commit('app/SET_USER', {
            avatar: props.profileData.avatar,
          })
        }
      })
    })

    const resetprofileData = () => {
      const profileData = JSON.parse(localStorage.getItem('profileData'))
      if (profileData.fio) {
        const parts = profileData.fio.split(' ')
        // eslint-disable-next-line no-param-reassign
        props.profileData.first_name = parts[0] || ''
        // eslint-disable-next-line no-param-reassign
        props.profileData.last_name = parts[1] || ''
        // eslint-disable-next-line no-param-reassign
        props.profileData.middle_name = parts[2] || ''
      } else {
        // eslint-disable-next-line no-param-reassign
        props.profileData.first_name = ''
        // eslint-disable-next-line no-param-reassign
        props.profileData.last_name = ''
        // eslint-disable-next-line no-param-reassign
        props.profileData.middle_name = ''
      }

      // eslint-disable-next-line no-param-reassign
      props.profileData.email = profileData.email || ''
      // eslint-disable-next-line no-param-reassign
      props.profileData.phone = profileData.phone || ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetprofileData)

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const fio = [props.profileData.last_name, props.profileData.first_name, props.profileData.middle_name].join(' ')
      store.dispatch('app-profile/updateProfile', {
        ...props.profileData,
        fio,
      }).then(() => {
        localStorage.setItem('userData', JSON.stringify({
          avatar: props.profileData.avatar,
          email: props.profileData.email,
          fio,
        }))
        store.commit('app/SET_USER', {
          avatar: props.profileData.avatar,
          email: props.profileData.email,
          fio,
        })
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      server,
      refFormObserver,
      getValidationState,
      loading,
      onSubmit,
      resetForm,
      refInputEl,
      previewEl,
      inputImageRenderer,
      avatarText,
    }
  },
}
</script>
