import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {},
  mutations: {},
  actions: {
    async fetchProfile(context, payload) {
      return axios.get('/profile', payload)
    },
    async updateProfile(context, payload) {
      return axios.put('/profile', payload).then(response => {
        const { data } = response

        if (data) {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: '',
              icon: 'InfoIcon',
              variant: 'success',
              text: 'Профиль обновлен',
            },
          })
        }

        return response
      })
    },
    async updateAccount(context, payload) {
      return axios.put('/account', payload).then(response => {
        const { data } = response

        if (data) {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: '',
              icon: 'InfoIcon',
              variant: 'success',
              text: 'Аккаунт обновлен',
            },
          })
        }

        return response
      })
    },
  },
}
