<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Основные</span>
        </template>
        <profile-general
          v-if="profileData"
          :profile-data="profileData"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- change account tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Аккаунт</span>
        </template>

        <profile-account
          v-if="profileData"
          :profile-data="profileData"
        />
      </b-tab>
      <!--/ change account tab -->
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import profileStoreModule from './profileStoreModule'
import ProfileGeneral from './ProfileGeneral.vue'
import ProfileAccount from './ProfileAccount.vue'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    ProfileGeneral,
    ProfileAccount,
  },
  setup() {
    const PROFILE_APP_STORE_MODULE_NAME = 'app-profile'

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.registerModule(PROFILE_APP_STORE_MODULE_NAME, profileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME)
    })

    const loading = ref(false)
    const profileData = ref(null)

    const fetchProfile = () => {
      loading.value = true

      store
        .dispatch('app-profile/fetchProfile')
        .then(response => {
          const { data } = response

          profileData.value = data

          if (profileData.value.fio) {
            const parts = profileData.value.fio.split(' ')
            profileData.value.first_name = parts[0] || ''
            profileData.value.last_name = parts[1] || ''
            profileData.value.middle_name = parts[2] || ''
          } else {
            profileData.value.first_name = ''
            profileData.value.last_name = ''
            profileData.value.middle_name = ''
          }

          loading.value = false
        })
        .catch(() => {
          profileData.value = null
          loading.value = false
        })
    }

    fetchProfile()

    return {
      profileData,
      loading,
    }
  },
}
</script>
