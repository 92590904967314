<template>
  <b-card>
    <!-- form -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="validationContext"
              name="Логин"
              rules="required"
            >
              <b-form-group
                label="Логин"
                :state="getValidationState(validationContext)"
                label-for="email"
              >
                <b-form-input
                  v-model="profileData.email"
                  :state="getValidationState(validationContext)"
                  name="email"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default
              name="Новый пароль"
            >
              <b-form-group
                label-for="account-new-password"
                label="Новый пароль"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="profileData.password"
                    :type="passwordFieldTypeNew"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Повторить пароль"
              :rules="'equal:' + profileData.password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Повторить пароль"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="profileData['retype-password']"
                    :state="getValidationState(validationContext)"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              <span>{{ $t('Save Changes') }}</span>
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('Reset') }}
            </b-button> -->
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
  BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
  BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required,
  equal,
} from '@validations'
import { ref } from '@vue/composition-api'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BSpinner,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    required,
    equal,
    passwordFieldTypeNew: 'password',
    passwordFieldTypeRetype: 'password',
  }),
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup(props) {
    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      store.dispatch('app-profile/updateAccount', {
        email: props.profileData.email || undefined,
        password: props.profileData.password,
      }).then(() => {
        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    const resetprofileData = () => {
      // eslint-disable-next-line no-param-reassign
      props.profileData.password = ''
      // eslint-disable-next-line no-param-reassign
      props.profileData['retype-password'] = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetprofileData)

    return {
      loading,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
